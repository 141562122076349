///react librarys - router - redux
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleCache } from '../../actions'

/// Icons

///static files
import styles from '../../assets/css/static-components/landing.module.css'
import {
  GrCar,
  GrMagic,
  GrMapLocation,
  GrPersonalComputer,
} from 'react-icons/gr'

const Landing = (props) => {
  // const { title, cache, handleCache } = props

  return (
    <div className={styles.body_landing}>
      <div className={styles.container_image}>
        <div className={styles.visibility}>
          <div className={styles.titleimage}>
            We are close <br />
            when you need us
          </div>
          <div className={styles.subtitleimage}>
            Feel free to use our application <br />
            and let us help
          </div>
          <Link className={styles.button} type="submit" to="/enter">
            surprise me
          </Link>
        </div>
      </div>
      <div className={styles.general_title}>request our service</div>
      <div className={styles.container_icons}>
        <div className={styles.circle_icon}>
          <GrPersonalComputer className={styles.icon} />
          Solicite
        </div>
        <div className={styles.circle_icon}>
          <GrMapLocation className={styles.icon} />
          We find you
        </div>
        <div className={styles.circle_icon}>
          <GrMagic className={styles.icon} />
          Bring you gasoline
        </div>
        <div className={styles.circle_icon}>
          <GrCar className={styles.icon} />
          Enjoy your day
        </div>
      </div>
      <div className={styles.general_title}>
        <div className={styles.button}>obviously I want</div>
      </div>
      <div className={styles.container_second_image}>
        <div className={styles.visibility_second}>
          <div className={styles.second_titleimage}>
            the best platform <br />
            at your disposal
          </div>
          <div className={styles.subtitleimage}>
            in a short time and much easier
          </div>
          <Link className={styles.button} type="submit" to="/enter">
            I can't resist!
          </Link>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    title: state.title,
    cache: state.cache,
  }
}
const mapDispatchToProps = {
  handleCache,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Landing))
