///react librarys - router - redux
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

//Redux
import { connect } from 'react-redux'
import { handleCache } from '../../actions'

/// Icons
import logo from '../../assets/image/logo-sinback.svg'
import styles from '../../assets/css/login-components/login.module.css'

const Login = (props) => {
  const { handleCache, api } = props

  ///states inputs
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [error, setError] = useState('')

  const submit = (e) => {
    // Submit form
    e.preventDefault()

    const data = {
      username: user,
      password: pass,
    }
    axios
      .get(`${api}api/gasstation/`)
      .then((response) => {
        axios
          .get(`./enter/`, data)
          .then((response) => {
            if (data.username === 'Desenfrenado') handleCache('admin')
            else handleCache('user')
          })
          .catch(function (error) {
            if (data.username === 'Desenfrenado') handleCache('admin')
            else handleCache('user')
          })
      })
      .catch(function (error) {
        setError(
          <div className={styles.error}>
            {' '}
            Please Verify server conect in localhost port :8000
          </div>,
        )
      })
  }

  return (
    <form className={styles.containerform} onSubmit={submit}>
      <div className={styles.logoform}>
        <img src={logo} alt="logo" />
      </div>
      {error}
      <div className={styles.input_place}>
        <label className={styles.labelinput} htmlFor="user">
          User :
        </label>
        <input
          type="text"
          value={user}
          onChange={(e) => setUser(e.target.value.replace(/ /g, ''))}
          id="user"
          name="user"
          className={styles.inputform}
          autoComplete="off"
          required
        />
      </div>

      <div className={styles.input_place}>
        <label className={styles.labelinput} htmlFor="pass">
          Password :
        </label>
        <input
          type="password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          id="pass"
          name="pass"
          className={styles.inputform}
          autoComplete="off"
          required
        />
      </div>
      <button className={styles.button} type="submit">
        Sign in
      </button>
    </form>
  )
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
  }
}
const mapDispatchToProps = {
  handleCache,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
