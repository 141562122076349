import React from "react";
import { connect } from "react-redux";

// components
import Header from "./static/header";
// import Footer from "./footer";

const Layout = (props) => {
  const { children } = props;
  return (
    <>
      <Header />
      {children}
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuActive: state.menuActive,
  };
};

export default connect(mapStateToProps, null)(Layout);
