///react librarys - router - redux
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleCache } from '../../actions'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/// Icons
import { GrUser, GrHome, GrLogout, GrProjects } from 'react-icons/gr'

///static files

import logo from '../../assets/image/logo-sinback.svg'
import styles from '../../assets/css/static-components/header.module.css'

const Header = (props) => {
  const { title, cache, handleCache, history } = props

  const closeSesion = () => {
    handleCache('')
    history.push('/')
  }

  return (
    <HelmetProvider>
      <header className={styles.back_header}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <div className={styles.header}>
          <Link className={styles.logo} to="/">
            <img src={logo} alt="Plataformimg" className={styles.logo_image} />
          </Link>
          <div className={styles.container_options}>
            <Link to="/" className={styles.text_head}>
              <GrHome className={styles.icon_head} />
              Home
            </Link>
            {cache === '' ? (
              <Link to="/enter" className={styles.text_head}>
                <GrUser className={styles.icon_head} />
                Enter
              </Link>
            ) : (
              <>
                <Link to="/enter" className={styles.text_head}>
                  <GrProjects className={styles.icon_head} />
                  Navegate
                </Link>
                <div className={styles.text_head} onClick={closeSesion}>
                  <GrLogout className={styles.icon_head} />
                  Log out
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </HelmetProvider>
  )
}
const mapStateToProps = (state) => {
  return {
    title: state.title,
    cache: state.cache,
  }
}
const mapDispatchToProps = {
  handleCache,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
