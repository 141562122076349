///react - routes
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
// import axios from "axios";

///redux
import { connect } from 'react-redux'
import { handleCache } from '../actions'

///components
import Layout from '../components/layout'
import Master from '../components/login/master'
import Landing from '../components/static/landing'

///styles
import './../assets/css/App.css'

const App = (props) => {
  return (
    <Router>
          <ScrollToTop>
        <div className="App">
          <Layout>
            <Switch>
              <Route path="/enter">
                <Master />
              </Route>
              <Route>
                <Landing />
              </Route>
            </Switch>
          </Layout>
        </div>
      </ScrollToTop>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}
const mapDispatchToProps = {
  handleCache,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
