///react librarys - router - redux
import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

///static files
// import { handleUser } from "../../actions";

///components
import Login from './login'
import Solicite from '../api_conections/solicite'
import Listservice from '../api_conections/listservice'

const Master = (props) => {
  ///props
  const { cache } = props

  return (
    <Switch>
      {cache !== '' ? (
        cache === 'admin' ? (
          <Listservice />
        ) : (
          <Solicite />
        )
      ) : (
        <>
          <Route exact path="/enter">
            <Login />
          </Route>
        </>
      )}
    </Switch>
  )
}
const mapStateToProps = (state) => {
  return {
    cache: state.cache,
  }
}
// const mapDispatchToProps = {
//   handleMenu,
// };

export default connect(mapStateToProps, null)(withRouter(Master))
