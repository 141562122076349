///react librarys - router - redux
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'

///static files
// import { handleUser } from "../../actions";
import styles from '../../assets/css/api_conections_components/solicite.module.css'
import { GrLocation } from 'react-icons/gr'
import { RiCarFill, RiGasStationFill } from 'react-icons/ri'

///components

const Solicite = (props) => {
  ///props
  const { api } = props

  const [x, setx] = useState(50)
  const [y, sety] = useState(24)
  const [datefilter, setDatefilter] = useState('')
  const [apirest, setApirest] = useState([])
  const [gasoline, setGasoline] = useState([])
  const [limit, setLimit] = useState(10)
  const [load, setLoad] = useState(false)
  const [deep, setDeep] = useState(1)

  const [service, setService] = useState({})

  const [update, setUpdate] = useState(true)

  const updateapi = () => {
    if (update) {
      axios
        .get(`${api}api/needgas/?x=${x}&y=${y}&filter_date=${datefilter}`)
        .then((response) => {
          setApirest(response.data)
          setLoad(true)
        })
        .catch(function (error) {
          // handle error
        })
      setUpdate(false)
    }
  }

  const loadGasoline = () => {
    axios
      .get(`${api}api/gasstation/`)
      .then((response) => {
        setGasoline(response.data)
      })
      .catch(function (error) {
        // handle error
      })
  }

  const solicite_service = () => {
    const datos = {
      user: 1,
      x: x,
      y: y,
      filter_date: datefilter,
    }

    axios
      .post(`${api}api/needgas/`, datos)
      .then((response) => {
        setLimit(100)
        setService(response.data)
      })
      .catch(function (error) {
        // handle error
      })
  }

  useEffect(loadGasoline, [api])

  useEffect(updateapi, [api, x, y, datefilter, update])

  return (
    load && (
      <div className={styles.container}>
        <div className={styles.title}>drivers near you</div>
        <div className={styles.mapcontainer}>
          <div
            className={styles.iconposition}
            style={{ bottom: `${y}%`, left: `${x}%` }}
          >
            <GrLocation />
          </div>

          {apirest.map(
            (e, i) =>
              limit > i && (
                <div
                  className={styles.iconcar}
                  style={{
                    bottom: `${e.y}%`,
                    left: `${e.x}%`,
                    color: `${
                      service.pk_driver !== undefined
                        ? parseInt(service.pk_driver) === parseInt(e.pk)
                          ? 'var(--color_second)'
                          : 'var(--transparent)'
                        : ''
                    }`,
                  }}
                  key={i}
                >
                  <RiCarFill />
                </div>
              ),
          )}

          {gasoline.map((e, i) => (
            <div
              className={styles.icongaso}
              style={{
                bottom: `${e.position_y}%`,
                left: `${e.position_x}%`,
                color: `${
                  service.gastation !== undefined
                    ? service.gastation === e.name
                      ? 'var(--color_second)'
                      : 'var(--transparent)'
                    : ''
                }`,
              }}
              key={i}
            >
              <RiGasStationFill />
            </div>
          ))}
        </div>

        {Object.keys(service).length > 0 ? (
          <>
            <div className={styles.title}>the driver is on his way</div>
            <div className={styles.timer}>{parseInt(service.time)} Minutes</div>
            <div className={styles.title}>
              {service.driver.name !== undefined
                ? service.driver.name
                : service.driver}
            </div>
          </>
        ) : (
          <>
            <div className={styles.inputcant}>
              <label className={styles.textinputcars} htmlFor="range_cant">
                Select number of drivers
              </label>
              <div className={styles.rangue_cant}>
                <input
                  type="range"
                  className={styles.inputran}
                  min="5"
                  max={`${apirest.length}`}
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  name="range_cant"
                  id="range_cant"
                />
              </div>
            </div>
            <div className={styles.modify_position}>
              <div className={styles.inputposition}>
                <label className={styles.textinputcars} htmlFor="position_x">
                  Position X
                </label>
                <input
                  type="range"
                  className={styles.inputran}
                  min="0"
                  max="100"
                  value={x}
                  onChange={(e) => setx(e.target.value)}
                  name="position_x"
                  id="position_x"
                  onClick={() => setUpdate(false)}
                  onMouseLeave={() => setUpdate(true)}
                />
              </div>
              <div className={styles.inputposition}>
                <label className={styles.textinputcars} htmlFor="position_y">
                  Position Y
                </label>
                <input
                  type="range"
                  className={styles.inputran}
                  min="0"
                  max="100"
                  value={y}
                  onChange={(e) => sety(e.target.value)}
                  name="position_y"
                  id="position_y"
                  onClick={() => setUpdate(false)}
                  onMouseLeave={() => setUpdate(true)}
                />
              </div>
            </div>
            <div className={styles.inputcant} style={{ display: 'none' }}>
              <label className={styles.textinputcars} htmlFor="mothyear">
                filter date
              </label>
              <div className={styles.rangue_cant}>
                <label className={styles.textinputcars} htmlFor="position_y">
                  {deep >= 1 && 'Year'} {deep >= 2 && 'Moth'}{' '}
                  {deep >= 3 && 'Day'}
                </label>
                <input
                  type="range"
                  className={styles.inputran}
                  min="1"
                  max="3"
                  value={deep}
                  onChange={(e) => setDeep(e.target.value)}
                  name="mothyear"
                  id="mothyear"
                />
                <label className={styles.textinputcars} htmlFor="position_y">
                  Date
                </label>
                <input
                  type="date"
                  className={styles.inputform}
                  min="5"
                  max={`${apirest.length}`}
                  value={datefilter}
                  onChange={(e) => setDatefilter(e.target.value)}
                  onClick={() => setUpdate(false)}
                  onMouseLeave={() => setUpdate(true)}
                  name="date"
                  id="date"
                />
              </div>
            </div>
            <div className={styles.button} onClick={solicite_service}>
              Request gasoline
            </div>
          </>
        )}

        <div className={styles.breath}></div>
      </div>
    )
  )
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
  }
}
// const mapDispatchToProps = {
//   handleMenu,
// };

export default connect(mapStateToProps, null)(withRouter(Solicite))
