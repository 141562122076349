///react librarys - router - redux
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

///static files
// import { handleUser } from "../../actions";

import styles from '../../assets/css/api_conections_components/listservice.module.css'
import axios from 'axios'

const Listservice = (props) => {
  ///props
  const { api } = props

  const [apirest, setApirest] = useState([])

  const [date, setdate] = useState('')
  const [id, setid] = useState('')

  const callapi = () => {
    // list services
    const datos = {
      date: date,
      driver: id === '' ? 0 : id,
    }

    axios
      .post(`${api}api/services/`, datos)
      .then((response) => {
        setApirest(response.data)
        console.log(response.data)
      })
      .catch(function (error) {
        // handle error
      })
  }

  //   const datareferenc = {
  //     active: false,
  //     client: 1,
  //     driver: 1,
  //     ending: '2021-09-13T11:10:27.195005Z',
  //     gastation: 'Pancho villa',
  //     pk: 1,
  //     position_x: 10,
  //   }
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className={styles.uniquefilter}>
          <label>Date filter</label>
          <input
            type="date"
            className={styles.inputform}
            value={date}
            onChange={(e) => setdate(e.target.value)}
          />
        </div>
        <div className={styles.uniquefilter}>
          <label>ID driver</label>
          <input
            type="number"
            className={styles.inputform}
            value={id}
            onChange={(e) => setid(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.button} onClick={callapi}>
        Filter
      </div>
      <div className={styles.containerlist}>
        <div className={styles.unicolist}>
          date - client - gas station - driver
        </div>
        {apirest.map((e, i) => (
          <div
            key={i}
            className={styles.unicolist}
            style={{
              border: e.active ? '1px dashed var(--color_second)' : '',
            }}
          >
            {e.ending} - {e.client} - {e.gastation} - {e.driver}
          </div>
        ))}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
  }
}
// const mapDispatchToProps = {
//   handleMenu,
// };

export default connect(mapStateToProps, null)(withRouter(Listservice))
